import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";

const CustomMetaTag = ({ title }) => {
  return (
    <Helmet prioritizeSeoTags>
      <title>ExamBazz Admin Panel | {title}</title>
      {/* Meta Tags */}
      <meta property="og:title" content="ExamBazz" />
      <meta property="og:type" content="website" />
      <meta name="author" content="Farhan Sadiq" />
      <meta name="copyright" content="ExamBazz" />
    </Helmet>
  );
};
CustomMetaTag.propTypes = {
  title: PropTypes.string.isRequired,
};
export default CustomMetaTag;
