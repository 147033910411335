import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Search, Trash2, Plus, ShieldCheck, ShieldX } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import useModminData from "@/hooks/useModminData";
import Swal from "sweetalert2";
import useAxiosSecure from "@/hooks/UseAxiosSecure";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { toast } from "sonner";

const Modmins = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [modminData, refetch] = useModminData(searchTerm);
  const axiosSecure = useAxiosSecure();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [newModmin, setNewModmin] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    role: "",
  });

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleBan = (modmin) => {
    Swal.fire({
      title: "Are you sure?! 😯",
      text: `You're ${modmin.isBanned ? "unbanning" : "banning"} ${
        modmin.name
      }!!! 😬`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes, ${modmin.isBanned ? "unban" : "ban"} him! 🙃`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const data = {
          _id: modmin._id,
          isBanned: modmin?.isBanned ? modmin.isBanned : false,
        };
        console.log(data);
        try {
          const res = await axiosSecure.post(`/modmin/ban`, data);

          if (res?.status === 200) {
            Swal.fire({
              title: `${modmin.isBanned ? "Unbanned" : "Banned"}!!!`,
              text: `${modmin.name} has been ${
                modmin.isBanned ? "unbanned" : "banned"
              } successfully 🙂.`,
              icon: "success",
            });
            return refetch();
          } else {
            Swal.fire({
              title: "Error!",
              text: res?.data?.message || "An error occurred...😣",
              icon: "error",
            });
          }
        } catch (error) {
          console.log("Error details:", error);

          Swal.fire({
            title: "Error!",
            text: error?.response?.data?.message || "An error occurred...😣",
            icon: "error",
          });
        }
      }
    });
  };

  const handleDelete = (modmin) => {
    Swal.fire({
      title: "Are you sure?! 🤨",
      text: `You're deleting ${modmin.name}! 😬`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Deleted!",
          text: `${modmin.name} has been deleted 😥.`,
          icon: "success",
        });
      }
    });
  };

  const handleAddModmin = async (event) => {
    event.preventDefault();
    setIsDialogOpen(false);
    const res = await axiosSecure.post("/modmin/add-modmin", newModmin);
    if (res?.status === 201) {
      toast.success(res.data.message);
      refetch();
      return;
    } else {
      toast.error("Error occurred");
    }
  };

  return (
    <div className="min-h-screen text-white p-8">
      <motion.h1
        className="text-4xl font-bold mb-8"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        Modmin Management
      </motion.h1>

      <div className="flex justify-between items-center mb-6">
        <div className="relative flex-grow mr-4">
          <Input
            type="text"
            placeholder="Search modmins..."
            value={searchTerm}
            onChange={handleSearch}
            className="pl-10 bg-gray-900 text-white border-gray-700 focus:border-white w-full"
          />
          <Search
            className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
            size={20}
          />
        </div>
        <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
          <DialogTrigger asChild>
            <Button className="bg-white text-black hover:bg-gray-200">
              <Plus size={20} className="mr-2" /> Add Modmin
            </Button>
          </DialogTrigger>
          <DialogContent className="bg-gray-900 text-white border-gray-700">
            <DialogHeader>
              <DialogTitle className="text-2xl font-bold">
                Add New Modmin
              </DialogTitle>
            </DialogHeader>
            <form onSubmit={handleAddModmin} className="space-y-4">
              <Input
                placeholder="Name"
                value={newModmin.name}
                onChange={(e) =>
                  setNewModmin({ ...newModmin, name: e.target.value })
                }
                required
                className="bg-gray-800 text-white border-gray-700"
              />
              <Input
                placeholder="Contact Number"
                value={newModmin.phoneNumber}
                onChange={(e) =>
                  setNewModmin({ ...newModmin, phoneNumber: e.target.value })
                }
                required
                className="bg-gray-800 text-white border-gray-700"
              />
              <Input
                type="email"
                placeholder="Email"
                value={newModmin.email}
                onChange={(e) =>
                  setNewModmin({ ...newModmin, email: e.target.value })
                }
                required
                className="bg-gray-800 text-white border-gray-700"
              />
              <Select
                value={newModmin.role}
                defaultValue="MODERATOR"
                onValueChange={(value) =>
                  setNewModmin({ ...newModmin, role: value })
                }
                required
              >
                <SelectTrigger className="bg-gray-800 text-white border-gray-700">
                  <SelectValue placeholder="Select a role" />
                </SelectTrigger>
                <SelectContent className="bg-gray-800 text-white border-gray-700">
                  <SelectItem value="DEVELOPER">Developer</SelectItem>
                  <SelectItem value="ADMIN">Admin</SelectItem>
                  <SelectItem value="MODERATOR">Moderator</SelectItem>
                </SelectContent>
              </Select>
              <Button
                type="submit"
                className="w-full bg-white text-black hover:bg-gray-200"
              >
                Add Modmin
              </Button>
            </form>
          </DialogContent>
        </Dialog>
      </div>

      <div className="overflow-x-auto">
        <Table className="w-full">
          <TableHeader>
            <TableRow className="bg-gray-900">
              <TableHead className="text-white">Name</TableHead>
              <TableHead className="text-white">Contact Number</TableHead>
              <TableHead className="text-white">Email</TableHead>
              <TableHead className="text-white">Role</TableHead>
              <TableHead className="text-white">Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            <AnimatePresence>
              {modminData.map((modmin) => (
                <motion.tr
                  key={modmin.id}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3 }}
                  className="border-b border-gray-800"
                >
                  <TableCell>{modmin.name}</TableCell>
                  <TableCell>{modmin.phoneNumber}</TableCell>
                  <TableCell>{modmin.email}</TableCell>
                  <TableCell>{modmin.role}</TableCell>
                  <TableCell>
                    <div className="flex space-x-2">
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Button
                              variant="outline"
                              size="sm"
                              onClick={() => handleBan(modmin)}
                            >
                              {modmin?.isBanned ? (
                                <ShieldCheck
                                  size={16}
                                  className="text-green-500"
                                />
                              ) : (
                                <ShieldX size={16} className="text-red-500" />
                              )}
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent>
                            <p>{`${modmin?.isBanned ? "Unban" : "Ban"}`}</p>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() => handleDelete(modmin)}
                        className="text-red-500 border-red-500 hover:bg-red-500 hover:text-white"
                      >
                        <Trash2 size={16} />
                      </Button>
                    </div>
                  </TableCell>
                </motion.tr>
              ))}
            </AnimatePresence>
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default Modmins;
