import { useQuery } from "@tanstack/react-query";
import useAxiosSecure from "./useAxiosSecure";
import useAuth from "./useAuth";

const useUserData = () => {
  const axiosSecure = useAxiosSecure();
  const { user, loading } = useAuth();
  const {
    refetch,
    isLoading,
    isPending,
    data: userData = [],
  } = useQuery({
    queryKey: ["userData", user?.email],
    enabled: !loading,
    queryFn: async () => {
      const email = user.email;
      const res = await axiosSecure.post("/", { email: email });
      return res.data;
    },
  });
  return [userData, refetch, isLoading, isPending];
};
export default useUserData;
