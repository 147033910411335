import Footer from "@/components/global/Footer";
import { Outlet } from "react-router-dom";
import Header from "@/components/global/Header/Header";

const Root = () => {
  return (
    <>
      <div className="flex flex-col min-h-screen justify-between overflow-hidden">
        <div className=" flex flex-col container mx-auto overflow-hidden">
          <Header />
          <div className=" mx-2">
            <Outlet />
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Root;
