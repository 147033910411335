import { motion } from "framer-motion";

const StatCard = ({ icon, title, value, change }) => (
  <motion.div
    className="bg-gray-900 p-6 rounded-lg shadow-lg"
    whileHover={{ scale: 1.05 }}
    transition={{ type: "spring", stiffness: 300 }}
  >
    <div className="flex items-center justify-between mb-4">
      <div className="text-white">{icon}</div>
      {/* <div
        className={`text-sm font-semibold ${
          change >= 0 ? "text-green-400" : "text-red-400"
        }`}
      >
        {change > 0 ? "+" : ""}
        {change}%
      </div> */}
    </div>
    <h3 className="text-lg font-semibold text-gray-300 mb-2">{title}</h3>
    <p className="text-3xl font-bold text-white">{value?.toLocaleString()}</p>
  </motion.div>
);

export default StatCard;
