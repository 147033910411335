import { useNavigate } from "react-router-dom";
import { Button } from "../ui/button";

export const Loading = () => {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <span className="loading text-primary loading-infinity loading-lg"></span>
      </div>
    );
  };
  
  export const GoBack = () => {
    const navigate = useNavigate();
    return (
      <div className="flex justify-start">
        <Button
        variant="outline"
          className=" my-4 "
          onClick={() => navigate(-1)}
        >
          Go Back
        </Button>
      </div>
    );
  };