import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { webAppTagLine } from "./Header/constant";

export default function Footer() {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  useEffect(() => {
    setCurrentYear(new Date().getFullYear());
  }, []);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        duration: 2,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 2,
      },
    },
  };

  return (
    <footer className="bg-black text-white overflow-hidden">
      <div className="container mx-auto px-6 py-12 overflow-hidden">
        <motion.div
          className="grid grid-cols-1 lg:grid-cols-3 gap-8 items-center"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          <motion.div
            variants={itemVariants}
            className="space-y-4 text-center lg:text-left"
          >
            <h2 className="md:text-3xl text-2xl font-bold">ExamBazz</h2>
            <p className="text-gray-400">{webAppTagLine}</p>
          </motion.div>
        </motion.div>

        <motion.div
          variants={itemVariants}
          className="mt-8 border-t border-gray-800 pt-8 flex flex-col lg:flex-row justify-between items-center"
        >
          <p className="text-sm text-gray-400">
            © {currentYear} ExamBazz. All rights reserved.
          </p>
          <div className="mt-4 lg:mt-0 flex space-x-4 text-sm text-gray-400">
            <a href="#" className="hover:text-white transition-colors">
              Privacy Policy
            </a>
            <a href="#" className="hover:text-white transition-colors">
              Terms of Service
            </a>
          </div>
        </motion.div>
      </div>

      <motion.div
        className="absolute inset-0 -z-10 pointer-events-none overflow-hidden"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 2, repeat: Infinity, repeatType: "reverse" }}
      >
        {[...Array(50)].map((_, i) => (
          <motion.div
            key={i}
            className="absolute h-1 w-1 bg-white rounded-full"
            style={{
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: [0, 1, 0] }}
            transition={{
              duration: Math.random() * 3 + 2,
              repeat: Infinity,
              repeatType: "loop",
              delay: Math.random() * 2,
            }}
          />
        ))}
      </motion.div>
    </footer>
  );
}
