import { motion } from "framer-motion";
import {
  Facebook,
  Youtube,
  MapPin,
  Phone,
  Mail,
  User,
  Trash2,
  ShieldX,
  ShieldCheck,
} from "lucide-react";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { useLoaderData, useNavigate } from "react-router-dom";
import { Loading } from "@/components/global/components";
import Swal from "sweetalert2";
import useAxiosSecure from "@/hooks/UseAxiosSecure";
import useOrganizationData from "@/hooks/useOrganizationData";
import {
  Tooltip,
  TooltipProvider,
  TooltipTrigger,
  TooltipContent,
} from "@/components/ui/tooltip";

const OrganizationDetails = () => {
  const organization = useLoaderData();
  const navigate = useNavigate();
  const axiosSecure = useAxiosSecure();
  const [, refetch] = useOrganizationData();

  const handleApprove = () => {
    Swal.fire({
      title: "Are you sure?! 😯",
      text: `You're approving the ${organization.name}, which was created by ${organization?.owner.name}!!! 🤠`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, approve it! 🙃",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const res = await axiosSecure.post(`/organization/approve`, {
            id: organization._id,
          });

          if (res?.status === 200) {
            Swal.fire({
              title: "Approved!",
              text: `${organization.name} has been approved successfully 😉.`,
              icon: "success",
            });
            refetch();
            navigate(".", { replace: true });
            return;
          } else {
            Swal.fire({
              title: "Error!",
              text: res?.data?.message || "An error occurred...",
              icon: "error",
            });
          }
        } catch (error) {
          console.log("Error details:", error);

          Swal.fire({
            title: "Error!",
            text: error?.response?.data?.message || "An error occurred...",
            icon: "error",
          });
        }
      }
    });
  };

  const handleBan = () => {
    Swal.fire({
      title: "Are you sure?! 😯",
      text: `You're ${organization.isBanned ? "unbanning" : "banning"} the ${
        organization.name
      }, which was created by ${organization?.owner.name}!!! 😬`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes, ${
        organization.isBanned ? "unban" : "ban"
      } it! 🙃`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const data = {
          _id: organization._id,
          isBanned: organization?.isBanned ? organization.isBanned : false,
        };
        console.log(data);
        try {
          const res = await axiosSecure.post(`/organization/ban`, data);

          if (res?.status === 200) {
            Swal.fire({
              title: `${organization.isBanned ? "Unbanned" : "Banned"}!!!`,
              text: `${organization.name} has been ${
                organization.isBanned ? "unbanned" : "banned"
              } successfully 🙂.`,
              icon: "success",
            });
            refetch();
            navigate(".", { replace: true });
            return;
          } else {
            Swal.fire({
              title: "Error!",
              text: res?.data?.message || "An error occurred...😣",
              icon: "error",
            });
          }
        } catch (error) {
          console.log("Error details:", error);

          Swal.fire({
            title: "Error!",
            text: error?.response?.data?.message || "An error occurred...😣",
            icon: "error",
          });
        }
      }
    });
  };

  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?! 🤨",
      text: `You're deleting the ${organization.name}, which was created by ${organization?.owner.name}!!! You won't be able to revert this! 😬`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Deleted!",
          text: `${organization.name} has been deleted 😥.`,
          icon: "success",
        });
      }
    });
  };

  if (!organization) {
    return <Loading />;
  }

  return (
    <div className="min-h-screen  text-white p-4 md:p-8">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="max-w-4xl mx-auto"
      >
        <div className="flex flex-col md:flex-row items-center md:items-start mb-8">
          <img
            src={organization.logoURL}
            alt={`${organization.name} logo`}
            className="w-32 h-32 object-cover rounded-full mb-4 md:mb-0 md:mr-8"
          />
          <div>
            <h1 className="text-3xl font-bold mb-2">{organization.name}</h1>
            <div className="flex items-center mb-2">
              <User className="mr-2" size={18} />
              <span className="text-gray-300">
                Owner: {organization.owner.name}
              </span>
            </div>
            <div className="flex space-x-4">
              <a
                href={organization.fbPage}
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-300 hover:text-white"
              >
                <Facebook size={24} />
              </a>
              <a
                href={organization.ytChannel}
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-300 hover:text-white"
              >
                <Youtube size={24} />
              </a>
            </div>
          </div>
        </div>

        <div
          className={
            organization.isVerified
              ? "grid grid-cols-1 md:grid-cols-2 gap-6 mb-8"
              : "grid grid-cols-1 mb-8"
          }
        >
          <Card className="text-center bg-gray-900 border-gray-700">
            <CardHeader>
              <CardTitle className="text-white">Contact Information</CardTitle>
            </CardHeader>
            <CardContent className="text-gray-300">
              <div className="flex items-center mb-2">
                <MapPin className="mr-2" size={18} />
                <span>{organization.address}</span>
              </div>
              <div className="flex items-center mb-2">
                <Phone className="mr-2" size={18} />
                <span>{organization.contactNumber}</span>
              </div>
              <div className="flex items-center">
                <Mail className="mr-2" size={18} />
                <span>{organization.contactEmail}</span>
              </div>
            </CardContent>
          </Card>

          {organization.isVerified && (
            <Card className="bg-gray-900 border-gray-700">
              <CardHeader>
                <CardTitle className="text-white">Statistics</CardTitle>
              </CardHeader>
              <CardContent className="text-gray-300">
                <div className="flex items-center justify-between mb-2">
                  <span>Enrolled Students:</span>
                  <span className="font-semibold text-white">
                    {organization.enrolledStudents}
                  </span>
                </div>
                <div className="flex items-center justify-between mb-2">
                  <span>Launched Courses:</span>
                  <span className="font-semibold text-white">
                    {organization.launchedCourses}
                  </span>
                </div>
                <div className="flex items-center justify-between">
                  <span>Exams Taken:</span>
                  <span className="font-semibold text-white">
                    {organization.takenExams}
                  </span>
                </div>
              </CardContent>
            </Card>
          )}
        </div>

        <Card className="bg-gray-900 border-gray-700 mb-8">
          <CardHeader>
            <CardTitle className="text-white">current Plan</CardTitle>
          </CardHeader>
          <CardContent className="text-gray-300">
            <p>{organization.currentPlan}</p>
          </CardContent>
        </Card>

        <Card className="bg-gray-900 border-gray-700 mb-8">
          <CardHeader>
            <CardTitle className="text-white">Teachers</CardTitle>
          </CardHeader>
          <CardContent>
            {organization.teachers.length > 0 ? (
              <ul className="space-y-2">
                {organization.teachers?.map((teacher, index) => (
                  <li key={index} className="flex items-center text-gray-300">
                    <User className="mr-2" size={18} />
                    <span>{teacher}</span>
                  </li>
                ))}
              </ul>
            ) : (
              <h1>No teacher has joined the organization</h1>
            )}
          </CardContent>
        </Card>

        {!organization.isVerified && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3, duration: 0.5 }}
          >
            <Button
              className="w-full bg-white text-black hover:bg-gray-200"
              onClick={() => handleApprove()}
            >
              Approve Organization
            </Button>
          </motion.div>
        )}
        <motion.div className="flex justify-end gap-10">
          {organization?.isVerified && (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button variant="outline" onClick={() => handleBan()}>
                    {organization?.isBanned ? (
                      <ShieldCheck size={16} className="text-green-500" />
                    ) : (
                      <ShieldX size={16} className="text-red-500" />
                    )}
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>{`${organization?.isBanned ? "Unban" : "Ban"}`}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          )}
          <Button
            variant="outline"
            onClick={() => handleDelete()}
            className="bg-red-600 hover:bg-red-700 text-white"
          >
            <Trash2 size={16} />
          </Button>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default OrganizationDetails;
