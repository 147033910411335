import { GoBack } from "@/components/global/components";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import useAxiosSecure from "@/hooks/UseAxiosSecure";
import useTeacherData from "@/hooks/useTeacherData";
import {
  CheckCircle,
  Search,
  ShieldCheck,
  ShieldX,
  Trash2,
  XCircle,
} from "lucide-react";
import { useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

const Teachers = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const [teacherData, refetch] = useTeacherData({
    searchStrings: searchTerm,
  });
  const axiosSecure = useAxiosSecure();

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleApprove = (teacher) => {
    Swal.fire({
      title: "Are you sure?! 😯",
      text: `You're approving ${teacher.name}!!! 🤠`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, approve it! 🙃",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const res = await axiosSecure.post(`/teacher/approve`, {
            id: teacher._id,
          });

          if (res?.status === 200) {
            Swal.fire({
              title: "Approved!",
              text: `${teacher.name} has been approved successfully 😉.`,
              icon: "success",
            });
            return refetch();
          } else {
            Swal.fire({
              title: "Error!",
              text: res?.data?.message || "An error occurred...",
              icon: "error",
            });
          }
        } catch (error) {
          console.log("Error details:", error);

          Swal.fire({
            title: "Error!",
            text: error?.response?.data?.message || "An error occurred...",
            icon: "error",
          });
        }
      }
    });
  };

  const handleBan = (teacher) => {
    Swal.fire({
      title: "Are you sure?! 😯",
      text: `You're ${teacher.isBanned ? "unbanning" : "banning"} ${
        teacher.name
      }!!! 😬`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes, ${teacher.isBanned ? "unban" : "ban"} him! 🙃`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const data = {
          _id: teacher._id,
          isBanned: teacher?.isBanned ? teacher.isBanned : false,
        };
        console.log(data);
        try {
          const res = await axiosSecure.post(`/teacher/ban`, data);

          if (res?.status === 200) {
            Swal.fire({
              title: `${teacher.isBanned ? "Unbanned" : "Banned"}!!!`,
              text: `${teacher.name} has been ${
                teacher.isBanned ? "unbanned" : "banned"
              } successfully 🙂.`,
              icon: "success",
            });
            return refetch();
          } else {
            Swal.fire({
              title: "Error!",
              text: res?.data?.message || "An error occurred...😣",
              icon: "error",
            });
          }
        } catch (error) {
          console.log("Error details:", error);

          Swal.fire({
            title: "Error!",
            text: error?.response?.data?.message || "An error occurred...😣",
            icon: "error",
          });
        }
      }
    });
  };

  const handleDelete = (teacher) => {
    Swal.fire({
      title: "Are you sure?! 🤨",
      text: `You're deleting ${teacher.name}! 😬`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Deleted!",
          text: `${teacher.name} has been deleted 😥.`,
          icon: "success",
        });
      }
    });
  };

  return (
    <div className="container mx-auto p-6 shadow-lg rounded-lg border-2 text-white">
      <h1 className="text-3xl font-bold mb-6">Teachers</h1>
      <GoBack />
      <div className="mb-6 relative">
        <Input
          type="text"
          placeholder="Search teacher..."
          value={searchTerm}
          onChange={handleSearch}
          className="pl-10 bg-gray-800 text-white border-gray-700"
        />
        <Search
          className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
          size={20}
        />
      </div>
      <div className="overflow-x-auto">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Name</TableHead>
              <TableHead>Contact Number</TableHead>
              <TableHead>Verified</TableHead>
              <TableHead>Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {teacherData.map((teacher) => (
              <TableRow key={teacher?._id}>
                <TableCell className="font-medium hover:text-emerald-400">
                  <Link to={`${teacher._id}`}>{teacher?.name}</Link>
                </TableCell>

                <TableCell>{teacher?.phoneNumber}</TableCell>
                <TableCell>
                  {teacher?.isVerified ? (
                    <CheckCircle className="text-green-500" size={20} />
                  ) : (
                    <XCircle className="text-red-500" size={20} />
                  )}
                </TableCell>
                <TableCell>
                  <div className="flex space-x-2">
                    {!teacher?.isVerified && (
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() => handleApprove(teacher)}
                        className="bg-green-600 hover:bg-green-700 text-white"
                      >
                        Approve
                      </Button>
                    )}
                    {teacher?.isVerified && (
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Button
                              variant="outline"
                              size="sm"
                              onClick={() => handleBan(teacher)}
                            >
                              {teacher?.isBanned ? (
                                <ShieldCheck
                                  size={16}
                                  className="text-green-500"
                                />
                              ) : (
                                <ShieldX size={16} className="text-red-500" />
                              )}
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent>
                            <p>{`${teacher?.isBanned ? "Unban" : "Ban"}`}</p>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    )}
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => handleDelete(teacher)}
                      className="bg-red-600 hover:bg-red-700 text-white"
                    >
                      <Trash2 size={16} />
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default Teachers;
