import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuItem,
} from "@/components/ui/dropdown-menu";
import { Link } from "react-router-dom";
import { navLinks } from "./constant";
import avatar from "@/assets/avatar.jpg";
import { LogOut } from "lucide-react";
import useAuth from "@/hooks/useAuth";
import useUserData from "@/hooks/useUserData";
import { toast } from "sonner";

const Header = () => {
  const { logOut } = useAuth();
  const { userData } = useUserData();
  return (
    <header className="flex h-20 w-full shrink-0 items-center px-4 md:px-6">
      <div className="flex items-center gap-2">
        <Link href="#" className="flex items-center gap-2" >
          <MountainIcon className="h-6 w-6" />
          <span className="sr-only">Acme Inc</span>
        </Link>
      </div>
      <div className="ml-auto flex items-center gap-2">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              variant="ghost"
              size="icon"
              className="rounded-full border w-10 h-10"
            >
              <img
                src={userData?.photoURL ? userData.photoURL : avatar}
                className="rounded-full w-10 h-10"
              />
              <span className="sr-only">Toggle user menu</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>Menu</DropdownMenuLabel>
            <DropdownMenuSeparator />
            {navLinks.map((navLink) => (
              <DropdownMenuItem key={navLink.name}>
                <Link to={navLink.path} className="flex items-center gap-2">
                  <navLink.icon className="size-4" />
                  <span>{navLink.name}</span>
                </Link>
              </DropdownMenuItem>
            ))}
            <DropdownMenuSeparator />

            <DropdownMenuItem
              onClick={() =>
                logOut()
                  .then(toast.success("Signed out successfully 😞"))
                  .catch((err) => {
                    toast.error(err.message);
                  })
              }
            >
              <p className="flex items-center gap-2">
                <LogOut className="h-4 w-4" />
                <span>Logout</span>
              </p>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </header>
  );
};

export default Header;

function MountainIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m8 3 4 8 5-5 5 15H2L8 3z" />
    </svg>
  );
}
