import { GoBack } from "@/components/global/components";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import useAxiosSecure from "@/hooks/UseAxiosSecure";
import useOrganizationData from "@/hooks/useOrganizationData";
import useTeacherData from "@/hooks/useTeacherData";
import {
  CheckCircle,
  Search,
  ShieldCheck,
  ShieldX,
  Trash2,
  XCircle,
} from "lucide-react";
import { useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

const Organizations = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [organizationData, refetch] = useOrganizationData({
    searchStrings: searchTerm,
  });
  const [teacherData] = useTeacherData();
  const axiosSecure = useAxiosSecure();

  const getTeacherNameById = (teacherId) => {
    const teacher = teacherData.find((teacher) => teacher._id === teacherId);
    return teacher ? teacher.name : "Unknown";
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleApprove = (org) => {
    Swal.fire({
      title: "Are you sure?! 😯",
      text: `You're approving the ${
        org.name
      }, which was created by ${getTeacherNameById(org?.owner)}!!! 🤠`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, approve it! 🙃",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const res = await axiosSecure.post(`/organization/approve`, {
            id: org._id,
          });

          if (res?.status === 200) {
            Swal.fire({
              title: "Approved!",
              text: `${org.name} has been approved successfully 😉.`,
              icon: "success",
            });
            return refetch();
          } else {
            Swal.fire({
              title: "Error!",
              text: res?.data?.message || "An error occurred...",
              icon: "error",
            });
          }
        } catch (error) {
          console.log("Error details:", error);

          Swal.fire({
            title: "Error!",
            text: error?.response?.data?.message || "An error occurred...",
            icon: "error",
          });
        }
      }
    });
  };

  const handleBan = (org) => {
    Swal.fire({
      title: "Are you sure?! 😯",
      text: `You're ${org.isBanned ? "unbanning" : "banning"} the ${
        org.name
      }, which was created by ${getTeacherNameById(org?.owner)}!!! 😬`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes, ${org.isBanned ? "unban" : "ban"} it! 🙃`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const data = {
          _id: org._id,
          isBanned: org?.isBanned ? org.isBanned : false,
        };
        console.log(data);
        try {
          const res = await axiosSecure.post(`/organization/ban`, data);

          if (res?.status === 200) {
            Swal.fire({
              title: `${org.isBanned ? "Unbanned" : "Banned"}!!!`,
              text: `${org.name} has been ${
                org.isBanned ? "unbanned" : "banned"
              } successfully 🙂.`,
              icon: "success",
            });
            return refetch();
          } else {
            Swal.fire({
              title: "Error!",
              text: res?.data?.message || "An error occurred...😣",
              icon: "error",
            });
          }
        } catch (error) {
          console.log("Error details:", error);

          Swal.fire({
            title: "Error!",
            text: error?.response?.data?.message || "An error occurred...😣",
            icon: "error",
          });
        }
      }
    });
  };

  const handleDelete = (org) => {
    Swal.fire({
      title: "Are you sure?! 🤨",
      text: `You're deleting the ${
        org.name
      }, which was created by ${getTeacherNameById(
        org?.owner
      )}!!! You won't be able to revert this! 😬`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Deleted!",
          text: `${org.name} has been deleted 😥.`,
          icon: "success",
        });
      }
    });
  };

  return (
    <div className="container mx-auto p-6 shadow-lg rounded-lg border-2 text-white">
      <h1 className="text-3xl font-bold mb-6">Organizations</h1>
      <GoBack />
      <div className="mb-6 relative">
        <Input
          type="text"
          placeholder="Search organization..."
          value={searchTerm}
          onChange={handleSearch}
          className="pl-10 bg-gray-800 text-white border-gray-700"
        />
        <Search
          className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
          size={20}
        />
      </div>
      <div className="overflow-x-auto">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Name</TableHead>
              <TableHead>Owner</TableHead>
              <TableHead>Contact Number</TableHead>
              <TableHead>Verified</TableHead>
              <TableHead>Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {organizationData.map((org) => (
              <TableRow key={org?._id}>
                <TableCell className="font-medium hover:text-emerald-400">
                  <Link to={`${org._id}`}>{org?.name}</Link>
                </TableCell>
                <TableCell className=" hover:text-yellow-400">
                  <Link to={`/teachers/${org?.owner}`}>
                    {getTeacherNameById(org?.owner)}
                  </Link>
                </TableCell>
                {/* Use correct field for Owner */}
                <TableCell>{org?.contactNumber}</TableCell>
                <TableCell>
                  {org?.isVerified ? (
                    <CheckCircle className="text-green-500" size={20} />
                  ) : (
                    <XCircle className="text-red-500" size={20} />
                  )}
                </TableCell>
                <TableCell>
                  <div className="flex space-x-2">
                    {!org?.isVerified && (
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() => handleApprove(org)}
                        className="bg-green-600 hover:bg-green-700 text-white"
                      >
                        Approve
                      </Button>
                    )}
                    {org?.isVerified && (
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Button
                              variant="outline"
                              size="sm"
                              onClick={() => handleBan(org)}
                            >
                              {org?.isBanned ? (
                                <ShieldCheck
                                  size={16}
                                  className="text-green-500"
                                />
                              ) : (
                                <ShieldX size={16} className="text-red-500" />
                              )}
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent>
                            <p>{`${org?.isBanned ? "Unban" : "Ban"}`}</p>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    )}
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => handleDelete(org)}
                      className="bg-red-600 hover:bg-red-700 text-white"
                    >
                      <Trash2 size={16} />
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default Organizations;
