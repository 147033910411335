import useOrganizationData from "@/hooks/useOrganizationData";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import StatCard from "./components/StatCards";
import {
  Building2,
  DollarSign,
  FileCheck,
  GraduationCap,
  Users,
} from "lucide-react";
import CustomMetaTag from "@/components/global/CustomMetaTags";

const useCountUp = (start, end, duration = 2) => {
  const [count, setCount] = useState(start);
  useEffect(() => {
    const increment = (end - start) / (duration * 60); // Increment for each frame assuming 60 FPS
    const step = () => {
      setCount((prev) => {
        if (prev < end) {
          return Math.min(prev + increment, end);
        }
        return end;
      });
    };
    const interval = setInterval(step, 1000 / 60); // Update every frame
    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [end, start, duration]);

  return count;
};

const HomePage = () => {
  const [organizationData] = useOrganizationData({ searchStrings: "" });
  const [stats, setStats] = useState({
    organizations: { value: 0 },
    verifiedOrgs: { value: 0 },
    students: { value: 0 },
    examsTaken: { value: 0 },
    earnings: { value: 0 },
  });

  const organizationsCount = useCountUp(0, stats.organizations.value);
  const verifiedOrgsCount = useCountUp(0, stats.verifiedOrgs.value);
  const studentsCount = useCountUp(0, stats.students.value);
  const examsTakenCount = useCountUp(0, stats.examsTaken.value);
  const earningsCount = useCountUp(0, stats.earnings.value);

  useEffect(() => {
    const verifiedOrganizations = organizationData.filter(
      (org) => org.isVerified
    ).length;

    setStats((prevStats) => ({
      ...prevStats,
      organizations: { value: organizationData.length },
      verifiedOrgs: { value: verifiedOrganizations },
      students: { value: 50000 },
      examsTaken: { value: 75000 },
      earnings: { value: 500000 },
    }));
  }, [organizationData]);

  return (
    <div className="text-white p-8">
      <CustomMetaTag title="Home" />
      <motion.h1
        className="text-4xl font-bold mb-8"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        ExamBazz Dashboard
      </motion.h1>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12">
        <StatCard
          icon={<Building2 size={24} />}
          title="Organizations"
          value={organizationsCount}
        />
        <StatCard
          icon={<FileCheck size={24} />}
          title="Verified Organizations"
          value={verifiedOrgsCount}
        />
        <StatCard
          icon={<Users size={24} />}
          title="Registered Students"
          value={studentsCount}
        />
        <StatCard
          icon={<GraduationCap size={24} />}
          title="Exams Taken"
          value={examsTakenCount}
        />
        <StatCard
          icon={<DollarSign size={24} />}
          title="Total Earnings"
          value={earningsCount}
        />
      </div>
    </div>
  );
};

export default HomePage;
