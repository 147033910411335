import {
  Building2,
  GraduationCap,
  Home,
  Settings2,
  SquarePen,
  UserRoundCog,
  Users,
} from "lucide-react";

export const navLinks = [
  { name: "Home", path: "/", icon: Home },
  {
    name: "Manage Organizations",
    path: "/organizations",
    icon: Building2,
  },
  {
    name: "Manage Teachers",
    path: "/teachers",
    icon: Users,
  },
  {
    name: "Manage Students",
    path: "/students",
    icon: GraduationCap,
  },
  {
    name: "Manage Modmins",
    path: "/modmins",
    icon: UserRoundCog,
  },
  {
    name: "Edit Profile",
    path: "/edit-profile",
    icon: SquarePen,
  },
  {
    name: "Settings",
    path: "/settings",
    icon: Settings2,
  },
];

export const webAppTagLine = "Your Ultimate Online Exam Hub";
