import Login from "@/pages/auth/Login";
import HomePage from "@/pages/home/HomePage";
import Root from "@/Root";
import { createBrowserRouter } from "react-router-dom";
import { PrivateRoutes, SignedInProtectedRoutes } from "./ProtectedRoutes";
import Organizations from "@/pages/organizations/Organizations";
import OrganizationDetails from "@/pages/organizations/OrganizationDetails";
import { axiosSecure } from "@/hooks/UseAxiosSecure";
import Teachers from "@/pages/teachers/Teachers";
import Modmins from "@/pages/modmins/Modmins";

const Router = createBrowserRouter([
  {
    path: "/",
    element: (
      <PrivateRoutes>
        <Root />
      </PrivateRoutes>
    ),
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "/organizations",
        element: <Organizations />,
      },
      {
        path: "/organizations/:id",
        loader: async ({ params }) => {
          const res = await axiosSecure.get(`/organizations/${params.id}`);
          return res.data.data;
        },
        element: <OrganizationDetails />,
      },
      {
        path: "/teachers",
        element: <Teachers />,
      },
      {
        path: "/modmins",
        element: <Modmins />,
      },
    ],
  },
  {
    path: "/sign-in",
    element: (
      <SignedInProtectedRoutes>
        <Login />
      </SignedInProtectedRoutes>
    ),
  },
]);

export default Router;
