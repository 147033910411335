import { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { auth } from "./../../firebase.config";
import {
  signOut,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  updatePassword,
} from "firebase/auth";
import { axiosSecure } from "@/hooks/useAxiosSecure";

export const AuthContext = createContext(null);
const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const currentUsingUser = auth.currentUser;

  // Sign in
  const logIn = (email, password) => {
    setLoading(true);
    return signInWithEmailAndPassword(auth, email, password);
  };

  // Sign Out
  const logOut = async () => {
    setLoading(true);
    await axiosSecure.get("/sign-out");
    return signOut(auth);
  };

  // Set password
  const setPassword = (password) => {
    setLoading(true);
    return updatePassword(currentUsingUser, password);
  };

  // Watch user
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      const loggedUser = {
        email: currentUser?.email || user?.email,
        uid: currentUser?.uid || user?.uid,
      };
      setUser(currentUser);

      if (currentUser) {
        await axiosSecure.post("/authorize", loggedUser);
      } else {
        await axiosSecure.get("/sign-out");
      }
      setLoading(false);
    });
    return () => {
      return unsubscribe();
    };
  }, [user?.email, user?.uid]);

  const authInfo = {
    user,
    logIn,
    logOut,
    loading,
    setLoading,
    setPassword,
  };
  return (
    <AuthContext.Provider value={authInfo}>{children}</AuthContext.Provider>
  );
};
AuthContextProvider.propTypes = {
  children: PropTypes.node,
};
export default AuthContextProvider;
