import PropTypes from "prop-types";
import { Navigate, useLocation } from "react-router-dom";

import { Loading } from "@/components/global/components";
import useAuth from "@/hooks/useAuth";


// Private Route Definition
export const PrivateRoutes = ({ children }) => {
  const { user, loading } = useAuth();
  const location = useLocation();
  if (loading) {
    return <Loading />;
  }
  if (user) {
    return <div>{children}</div>;
  }
  return <Navigate to={"/sign-in"} state={{ from: location }} replace />;
};

PrivateRoutes.propTypes = {
  children: PropTypes.node,
};

// Prevent navigation to the sign in page
export const SignedInProtectedRoutes = ({ children }) => {
  const { user } = useAuth();

  if (!user) {
    return <div>{children}</div>;
  }
  return <Navigate to={"/"} />;
};
SignedInProtectedRoutes.propTypes = {
  children: PropTypes.node,
};
