import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "./index.css";
import { HelmetProvider } from "react-helmet-async";
import { RouterProvider } from "react-router-dom";
import Router from "@/routes/Route.jsx";
import { Toaster } from "sonner";
import AuthContextProvider from "@/contexts/AuthContext";
import { ThemeProvider } from "@/components/theme-provider";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://b118b098edb0145c504e444cf1eea42e@o4507866995490816.ingest.de.sentry.io/4507917525844048",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <AuthContextProvider>
      <QueryClientProvider client={queryClient}>
        <HelmetProvider>
          <RouterProvider router={Router} />
          <ThemeProvider defaultTheme="dark" storageKey="theme">
            <Toaster position="top-right" richColors duration={2000} />
          </ThemeProvider>
        </HelmetProvider>
      </QueryClientProvider>
    </AuthContextProvider>
  </React.StrictMode>
);
