import axios from "axios";
import { useEffect } from "react";
import { toast } from "sonner";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase.config";

export const axiosSecure = axios.create({
  // baseURL: "http://localhost:8000/v1/modmins",
  baseURL: "https://api.exambazz.com/v1/modmins",
  withCredentials: true,
});

const useAxiosSecure = () => {
  useEffect(() => {
    axiosSecure.interceptors.response.use(
      (res) => {
        return res;
      },
      async (error) => {
        console.error("Interceptor error:", error.response);
        if (error.response?.status === 401 || error.response?.status === 403) {
          await signOut(auth).then(() => {
            toast.warning("Access Denied, please sign in");
          });
        }
      }
    );
    return () => {
      axiosSecure.interceptors.response.eject();
    };
  }, []);

  return axiosSecure;
};

export default useAxiosSecure;
