import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import Swal from "sweetalert2";
import useAuth from "@/hooks/useAuth";
import { logEvent, setUserId, setUserProperties } from "firebase/analytics";
import { analytics } from "./../../../firebase.config";
import { toast } from "sonner";
import { Eye, EyeOff } from "lucide-react";
import CustomMetaTag from "@/components/global/CustomMetaTags";

const Login = () => {
  const { logIn } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    // Handle login logic here
    await logIn(email, password)
      .then(() => {
        setUserId(analytics, email);
        setUserProperties(analytics, { webApp: "admin_panel" });
        logEvent(analytics, "logged_in_to_the_admin_panel");
        toast.success("Welcome to the multiverse of ExamBazz! 😎");
      })
      .catch((err) => {
        if (err.message === "Firebase: Error (auth/invalid-credential).") {
          return toast.error("Invalid email or password 😒");
        }
        if (err.message === "Firebase: Error (auth/user-disabled).") {
          return toast.error(
            "You are banned..! 😣 Please contact with the administrator 😅"
          );
        }
        if (err.message === "Firebase: Error (auth/too-many-requests).") {
          return toast.error(
            "You've requested too many times..! 😑 Please wait a while to try again"
          );
        }
        if (err.message === "Firebase: Error (auth/network-request-failed).") {
          return toast.error("Please check your internet connection 🤨");
        }
        toast.error("Error occurred, please contact with the developer! 😓😓");
      });
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    // Handle forgot password logic here
    console.log("Forgot password requested for:", forgotPasswordEmail);
    setIsModalOpen(false);
    setForgotPasswordEmail("");
    Swal.fire({
      title: "Email Sent",
      text: "Check your email to reset your password 🙃",
      icon: "success",
    });
  };

  return (
    <div className="flex items-center justify-center min-h-screen ">
      <CustomMetaTag title="Sign In" />
      <div className="w-full max-w-md p-8 space-y-8  rounded-xl shadow-md">
        <h2 className="text-3xl font-bold text-center ">Sign In</h2>
        <form onSubmit={handleLogin} className="space-y-6">
          <div>
            <Label htmlFor="email">Email</Label>
            <Input
              id="email"
              type="email"
              autoComplete="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div>
            <Label htmlFor="password">Password</Label>
            <div className="relative">
              <Input
                id="password"
                type={showPassword ? "text" : "password"}
                placeholder="Enter your password"
                value={password}
                autoComplete="current-password"
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <Button
                type="button"
                variant="ghost"
                size="icon"
                className="absolute right-0 top-0 h-full px-3 py-2 hover:bg-transparent"
                onClick={togglePasswordVisibility}
                aria-label={showPassword ? "Hide password" : "Show password"}
              >
                {showPassword ? (
                  <EyeOff className="h-4 w-4 text-gray-500" />
                ) : (
                  <Eye className="h-4 w-4 text-gray-500" />
                )}
              </Button>
            </div>
          </div>
          <Button type="submit" className="w-full">
            Log in
          </Button>
        </form>
        <div className="text-center">
          <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
            <DialogTrigger asChild>
              <Button
                variant="link"
                className="text-sm text-blue-600 hover:underline"
              >
                Forgot password?
              </Button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Forgot Password?</DialogTitle>
                <DialogDescription>
                  No prob at all, just give your email and let us do the
                  magic!🙂👍
                </DialogDescription>
              </DialogHeader>
              <form onSubmit={handleForgotPassword} className="space-y-4">
                <div>
                  <Label htmlFor="forgotPasswordEmail">Email</Label>
                  <Input
                    className="mt-3"
                    id="forgotPasswordEmail"
                    type="email"
                    autoComplete="email"
                    placeholder="Enter your email"
                    value={forgotPasswordEmail}
                    onChange={(e) => setForgotPasswordEmail(e.target.value)}
                    required
                  />
                </div>
                <Button type="submit" className="w-full">
                  Reset Password
                </Button>
              </form>
            </DialogContent>
          </Dialog>
        </div>
      </div>
    </div>
  );
};

export default Login;
